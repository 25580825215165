import {
  Box,
  Button,
  Card,
  makeStyles,
  TextField,
  Typography,
  Input,
  Switch,
  FormHelperText,
  Collapse,
  CardContent,
} from "@material-ui/core";
import { Edit, Image as ImageIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import PaletteIcon from '@mui/icons-material/Palette';

import style from "../../../../assets/jss/material-dashboard-react/views/screenStyle";

import AddIcon from "../../../../assets/img/icons/svg/Ic_add.svg";
import deleteBucket from "assets/img/platformScreenVisualization/deleteBucket.svg";
import CloseIcon from "assets/img/icons/svg/close.svg";

import Dialog from "components/Dialog/Dialog";

import {
  CreateScreenAction,
  getAllScreenAction,
  getScreenResetAction,
  screenReorderAction,
  deleteScreenAction,
} from "screens/PlatformManager/actions/PlatformAction";

import { connect } from "react-redux";
import axios from "axios";
import { publishScreenAction, updateScreenDetails } from "screens/PlatformManager/actions/PlatformAction";
import SortableScreensList from "components/Sortable/SortableScreensList";
import SortableScreensItem from "components/Sortable/SortableScreensItem";
import { clearUpdateScreenDetails } from "screens/PlatformManager/actions/PlatformAction";
import { clearCreateScreenData } from "screens/PlatformManager/actions/PlatformAction";
import { getAllScreensDataAction } from "screens/PlatformManager/actions/PlatformAction";
import { useHistory } from "react-router-dom";
import PushPinIcon from '@mui/icons-material/PushPin';

const useStyle = makeStyles(style);

const Screens = props => {
  const history = useHistory();
  const classes = useStyle();
  const [newOne, setNewOne] = useState(false);
  const [screenCreate, setScreenCreate] = useState({ displayStyle: "name" });
  const [error, setError] = useState(false);
  const errorMessage = "Please enter the screen name.";
  const [checked, setChecked] = useState(false);
  const [checkedEdit, setCheckedEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [order, setOrder] = useState(props.allScreens);
  const [pinnedScreens, setPinnedScreens] = useState({});

  const [screenName, setScreenName] = useState("");

  const [expanded, setExpanded] = useState(-1);
  const [screenDetails, setScreenDetails] = useState({ newScreenName: "", screenIcon: null });

  const handleadd = () => {
    props.accessLevel !== "user"
      ? setNewOne(true)
      : [
        props.setSnackMessage("You do not have access to add a new screen."),
        props.setSnackSeverity("warning"),
        props.setSnackOpen(true),
      ];
  };

  const handleAddScreen = () => {
    props.setValue(0);

    const screenName = screenCreate?.screenName?.toLowerCase();

    if (screenName === "details" || screenName === "404") {
      props.setSnackMessage("You cannot create default screens.");
      props.setSnackSeverity("warning");
      props.setSnackOpen(true);
      return;
    }

    if (!screenCreate?.screenName) {
      setError(true);
      return;
    }

    props.CreateScreenAction(screenCreate);
    setNewOne(false);
    setScreenCreate({ ...screenCreate, displayStyle: "name", screenIcon: "" });
    setChecked(false);
  };

  const handleCloseAddScreen = () => {
    setNewOne(false);
    setScreenCreate({});
    setChecked(false);
  };

  const handleScreenName = event => {
    setScreenCreate({ ...screenCreate, screenName: event.target.value });
    setError(false);
  };

  const handleScreenType = event => {
    setChecked(event.target.checked);

    if (screenCreate?.screenIcon) {
      setScreenCreate({ ...screenCreate, displayStyle: "icon" });
      return;
    }

    setScreenCreate({ ...screenCreate, displayStyle: "name" });

    if (event.target.checked)
      setTimeout(() => {
        setChecked(false);
        props.setSnackMessage("Please upload a screen icon.");
        props.setSnackSeverity("warning");
        props.setSnackOpen(true);
      }, 200);
  };

  const getRatio = async url => {
    return new Promise(resolve => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(img.width / img.height);
    });
  };

  const getBase64 = async file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async e => {
        const ratio = await getRatio(e.target.result);
        resolve({ b64: reader.result, ratio });
      };
    });
  };

  const handleIconImage = async event => {
    const files = event.target.files;
    const file = files[0];

    if (file.type !== "image/svg+xml") {
      props.setSnackMessage("Only svg files are supported.");
      props.setSnackSeverity("error");
      props.setSnackOpen(true);
      return;
    }

    const image = await getBase64(file);

    const openErrorSnacks = () => {
      props.setSnackMessage("Image could not be uploaded. Please try again.");
      props.setSnackSeverity("error");
      props.setSnackOpen(true);
    };

    if (!image.b64.length) {
      openErrorSnacks();
      return;
    }

    const checkAR = (image, platform) => {
      if (platform === "wide") return image.ratio > 1 && image.ratio < 2 ? true : false;
      if (platform === "small") return image.ratio <= 1 ? true : false;
      return false;
    };

    if (!checkAR(image, "small")) {
      props.setSnackMessage("This platform supports 1x1 image ratio only.");
      props.setSnackSeverity("warning");
      props.setSnackOpen(true);
      return;
    }

    const newData = image.b64.replace("data:image/svg+xml", "data:image/svg");

    const S3image = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-screen-icon`,
      { file: newData },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    if (S3image.data.hzStatus === 200) {
      setChecked(true);
      setScreenCreate({
        ...screenCreate,
        screenIcon: S3image.data.uploadResult.Location,
        displayStyle: "icon",
      });
      return;
    }

    openErrorSnacks();
  };

  const handleEditIconImage = async event => {
    const files = event.target.files;
    const file = files[0];

    if (file.type !== "image/svg+xml") {
      props.setSnackMessage("Only svg files are supported.");
      props.setSnackSeverity("error");
      props.setSnackOpen(true);
      return;
    }

    const image = await getBase64(file);

    const openErrorSnacks = () => {
      props.setSnackMessage("Image could not be uploaded. Please try again.");
      props.setSnackSeverity("error");
      props.setSnackOpen(true);
    };

    if (!image.b64.length) {
      openErrorSnacks();
      return;
    }

    const checkAR = (image, platform) => {
      if (platform === "wide") return image.ratio > 1 && image.ratio < 2 ? true : false;
      if (platform === "small") return image.ratio <= 1 ? true : false;
      return false;
    };

    if (!checkAR(image, "small")) {
      props.setSnackMessage("This platform supports 1x1 image ratio only.");
      props.setSnackSeverity("warning");
      props.setSnackOpen(true);
      return;
    }

    const newData = image.b64.replace("data:image/svg+xml", "data:image/svg");

    const S3image = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-screen-icon`,
      { file: newData },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    if (S3image.data.hzStatus === 200) {
      setCheckedEdit(true);
      setScreenDetails({
        ...screenDetails,
        screenIcon: S3image.data.uploadResult.Location,
        displayStyle: "icon",
      });
      return;
    }

    openErrorSnacks();
  };

  const deleteScreen = screen => {
    if (props.accessLevel === "user") {
      props.setSnackMessage("You do not have access to delete screens.");
      props.setSnackSeverity("warning");
      props.setSnackOpen(true);
      return;
    }

    if (screen.name === "DETAILS" || screen.name == "404") {
      props.setSnackMessage("Default screens cannot be deleted.");
      props.setSnackSeverity("warning");
      props.setSnackOpen(true);
      return;
    }

    setScreenName(screen);
    setOpenDialog(true);
  };

  const screenDeleted = screen => {
    props.setValue(0);
    props.deleteScreenAction(screen);

    setOpenDialog(false);
  };

  const handleEditScreen = (x, i) => {
    if (props.accessLevel === "user") {
      props.setSnackMessage("You do not have access to edit screens.");
      props.setSnackSeverity("warning");
      props.setSnackOpen(true);
      return;
    }

    setScreenDetails({
      ...screenDetails,
      p_id: x.p_id,
      platform: x.platform.shortName,
      name: x.name,
      displayStyle: x.displayStyle,
      screenIcon: x.screenIcon,
      newScreenName: x.name,
    });
    setExpanded(expanded === i ? -1 : i);
    setCheckedEdit(x.displayStyle === "icon" ? true : false);
  };

  const handleEditScreenName = event => setScreenDetails({ ...screenDetails, newScreenName: event?.target?.value });

  const handleEditScreenType = event => {
    setCheckedEdit(event.target.checked);

    if (screenDetails?.screenIcon && event.target.checked) {
      setScreenDetails({ ...screenDetails, displayStyle: "icon" });
      return;
    }

    setScreenDetails({ ...screenDetails, displayStyle: "name" });

    if (event.target.checked)
      setTimeout(() => {
        setCheckedEdit(false);
        props.setSnackMessage("Please upload a screen icon.");
        props.setSnackSeverity("warning");
        props.setSnackOpen(true);
      }, 200);
  };

  const handleScreenUpdate = index => {
    setExpanded(-1);
    props.setValue(index);
    props.updateScreenDetails(screenDetails);
  };

  const updatePinning = (id, data) => {
    console.log('id -->', id);
    setPinnedScreens(prev => {
      const isCurrentlyPinned = prev[id] || false;
      return ({
        ...prev, [id]: !isCurrentlyPinned,
        lateset: id
      })
    })
    props.updateScreenDetails(data)
  }

  console.log("screenPinning -->", pinnedScreens)
  useEffect(() => {
    const _pinnedScreen = { }
    console.log(props.allScreens,"allScreensallScreens")
    if (props?.allScreens?.length && Object.keys(pinnedScreens).length <= 0) {
      console.log('props.allScreens insde usef\ef -->', props.allScreens)
      // alert("useEffect")
      props?.allScreens.map(screen => {
        console.log('screen --> ', screen);
        if(screen?.pinning === true) {
          _pinnedScreen[screen?._id ?? screen?.id] = true
        } else {
          _pinnedScreen[screen?._id ?? screen?.id] = false
        }
      })
    }

    console.log('_pinnedScreen --> ', _pinnedScreen);
  
    if(Object.keys(_pinnedScreen).length > 0) {
      setPinnedScreens(prev => {
        return Object.assign(prev, _pinnedScreen)
      })
    }
    
  }, [props.allScreens]);

  useEffect(() => {
    setScreenCreate({
      ...screenCreate,
      status: "DRAFT",
      params: {
        projectId: props.projectId,
        platform: props.platform,
      },
      displayOrder: props.allScreens.length || 0,
    });
  }, [newOne]);

  useEffect(() => {
    if (order?.[0]?._id !== props.allScreens?.[0]?._id) setOrder(props.allScreens);
    setExpanded(-1);
  }, [props.allScreens]);

  useEffect(() => {
    if (props.reorderScreenData?.data?.hzStatus !== 200 && Object.keys(props.reorderScreenData).length) {
      setOrder(props.allScreens);
      props.setSnackMessage(props.reorderScreenData?.message);
      props.setSnackSeverity("error");
      props.setSnackOpen(true);
    }
  }, [props.reorderScreenData]);

  useEffect(() => {
    if (props.updatedScreenDetails.length) {
      setOrder(props.allScreens);
      props.clearUpdateScreenDetails();
    }
  }, [props.updatedScreenDetails]);

  useEffect(() => {
    if (props.createScreen?.data?.data && Object.keys(props.createScreen).length) setOrder(props.allScreens);
  }, [props.createScreen]);

  useEffect(() => {
    if (props.deleteScreenData && !Object.keys(props.deleteScreenData).length) return;

    props.setScreenDeleted(true);

    if (props.deleteScreenData?.data?.hzStatus === 200) {
      const allScreens = props.allScreens.filter(screen => screen._id !== screenName._id);
      props.getAllScreensDataAction(allScreens);
      setOrder(allScreens);
      return;
    }
  }, [props.deleteScreenData]);

  useEffect(() => {
    const orderChanged = order?.some((screen, index) => screen._id !== props.allScreens?.[index]?._id);

    if (!orderChanged) return;

    const newOrder = order.map((screen, index) => {
      return { name: screen.name, displayOrder: index };
    });

    props.screenReorderAction(newOrder, props?.projectDetails?.p_id, props.data.platform);
  }, [order]);

  return (
    <>
      <Box m={2} paddingBottom="6px">
        <Box display="flex" flexDirection="row" justifyContent="space-between" m="0 16px 6px">
          <Typography className={classes.typo}>Drag and Drop screens to reorder them</Typography>
          {newOne ? (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
              <Button variant="contained" className={classes.saveButton} onClick={handleAddScreen}>
                <Typography className={classes.ButtonText}>Save</Typography>
              </Button>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{ cursor: "pointer" }}
              onClick={handleadd}
            >
              <img src={AddIcon} />
              <Typography className={classes.addnew}>Add New</Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.screensContainer}>
          {newOne ? (
            <Box marginTop="6px" px="8px" key="newScreen">
              <Card className={classes.createScreenCard} elevation={0}>
                <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                  <Box display="flex" flexDirection="row">
                    <Box px="16px">
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Enter Screen Name"
                        variant="outlined"
                        onChange={handleScreenName}
                        required
                      />
                      <FormHelperText>{error ? errorMessage : null}</FormHelperText>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <label htmlFor="upload-photo">
                        <Input
                          style={{ display: "none" }}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          multiple
                          onChange={handleIconImage}
                          onClick={event => (event.target.value = null)}
                          required
                          inputProps={{ accept: ".svg" }}
                        />
                        <Box display="flex" alignItems="center" justifyContent="space-evenly">
                          <Box
                            className={classes.screenIconContainer}
                            style={
                              screenCreate?.screenIcon
                                ? {
                                  background: `repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) center / 2px 2px`,
                                  cursor: "pointer",
                                }
                                : { cursor: "pointer" }
                            }
                          >
                            {screenCreate?.screenIcon ? (
                              <img src={screenCreate?.screenIcon} width={30} height={30} />
                            ) : (
                              <ImageIcon className={classes.screenIcon} />
                            )}
                          </Box>
                        </Box>
                      </label>
                    </Box>
                  </Box>
                  <Box pr="3%">
                    <img className={classes.screenDelete} src={CloseIcon} onClick={handleCloseAddScreen} />
                  </Box>
                </Box>
                <Box className={classes.iconPrompt}>
                  <Box>
                    <Typography className={classes.inputlabel} style={{ paddingTop: "10px", paddingLeft: "15px" }}>
                      Do you want to show the Icon ?
                    </Typography>
                  </Box>
                  <Box>
                    <Switch size="small" className={classes.switch} checked={checked} onChange={handleScreenType} />
                  </Box>
                </Box>
              </Card>
            </Box>
          ) : null}

          <SortableScreensList
            items={order}
            onChange={setOrder}
            snackSeverity="warning"
            accessLevel={props.accessLevel}
            setSnackOpen={props.setSnackOpen}
            setSnackMessage={props.setSnackMessage}
            snackMessage="You do not have access to reorder screens."
            setExpanded={setExpanded}
            renderItem={(item, index, items) => {
              console.log(items,"items")
              return (
                <SortableScreensItem key={item} id={item} item={item} index={index} items={items}>
                  <Card className={classes.screenCard} elevation={0}>
                    <Box className={classes.collapsedScreenCard}>
                      <Box display="flex" alignItems="center" pl="24px">
                        <Typography className={classes.screenNo}>{index + 1}.</Typography>
                        <Box
                          className={classes.screenIconContainer}
                          style={
                            items?.[index]?.screenIcon && items?.[index].displayStyle === "icon"
                              ? {
                                background: `repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) center / 2px 2px`,
                              }
                              : {}
                          }
                        >
                          {items?.[index]?.screenIcon && items?.[index].displayStyle === "icon" ? (
                            <img
                              src={items?.[index]?.screenIcon ? items?.[index].screenIcon : ""}
                              width={30}
                              height={30}
                            />
                          ) : (
                            <ImageIcon className={classes.screenIconList} />
                          )}
                        </Box>
                        <Typography className={classes.screenName} style={{ paddingLeft: "8px" }}>
                          {items?.[index]?.name}
                        </Typography>
                      </Box>
                      <Box className="screen-action-container">
                        <Button
                          className={classes.screenAction}
                          style={{ minWidth: "22px" }}
                          onClick={() => {
                            history.push({
                              pathname: "/admin/projectsplateformmanager/screens/theme",
                              state: {
                                  p_id:items?.[index]?.p_id,
                                  platform:items?.[index]?.platform?.shortName,
                                  screenId:items?.[index]?._id,
                                  screenName:items?.[index]?.name,
                                  theme:items?.[index]?.theme,
                                prevPage: "PlatformVis"
                              },
                            })
                          }}
                        >
                          <PaletteIcon style={{ width: "22px" }} />
                        </Button>
                        <Button
                          className={classes.screenAction}
                          style={{ minWidth: "22px" }}
                          onClick={() => handleEditScreen(items?.[index], index)}
                        >
                          <Edit style={{ width: "22px" }} />
                        </Button>
                        <Button
                          className={classes.screenAction}
                          style={{ minWidth: "22px" }}
                          onClick={() => deleteScreen(items?.[index])}
                        >
                          <img style={{ height: "22px" }} src={deleteBucket} />
                        </Button>
                        <Button style={{ minWidth: "22px", borderRadius: "25px" }}
                          onClick={() => {
                            if (items?.[index]?._id && items?.[index]?.p_id) {
                              let currentPinning = items?.[index]?.pinning ?? false;
                              let body = {
                                pinning: !currentPinning
                              };                              
                              let parameters = {
                                p_id: items?.[index]?.p_id,
                                platform: items?.[index]?.platform?.shortName,
                                screenName: items?.[index]?.name
                              };
                              console.log('parameters', parameters)
                              console.log('body', body)                              
                              updatePinning(items?.[index]?._id, {...parameters, ...body, context:"pinning"})
                            }
                          }}
                        >
                          <PushPinIcon
                            style={{
                              cursor: "pointer",
                              height: "23px",
                              color: !pinnedScreens[items?.[index]?._id] ? "#808080":"#008000"
                            }}
                          />
                        </Button>
                      </Box>
                    </Box>
                    <Collapse in={expanded === index}>
                      <CardContent>
                        <Box display="flex" flexDirection="row">
                          <Box px="16px">
                            <TextField
                              fullWidth
                              size="small"
                              placeholder="Enter Screen Name"
                              variant="outlined"
                              onChange={handleEditScreenName}
                              required
                              value={screenDetails?.newScreenName}
                            />
                            <FormHelperText>{error ? errorMessage : null}</FormHelperText>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <label htmlFor="edit-photo">
                              <Input
                                style={{ display: "none" }}
                                id="edit-photo"
                                name="edit-photo"
                                type="file"
                                multiple
                                onChange={handleEditIconImage}
                                onClick={event => (event.target.value = null)}
                                required
                                inputProps={{ accept: ".svg" }}
                              />
                              <Box display="flex" alignItems="center" justifyContent="space-evenly">
                                <Box
                                  className={classes.screenIconContainer}
                                  style={
                                    screenDetails?.screenIcon
                                      ? {
                                        background: `repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) center / 2px 2px`,
                                      }
                                      : {}
                                  }
                                >
                                  {screenDetails?.screenIcon ? (
                                    <img
                                      src={
                                        screenDetails?.screenIcon
                                          ? screenDetails?.screenIcon
                                          : items?.[index]?.screenIcon
                                      }
                                      style={{ width: "30px", cursor: "pointer" }}
                                    />
                                  ) : (
                                    <ImageIcon className={classes.screenIcon} />
                                  )}
                                </Box>
                              </Box>
                            </label>
                          </Box>
                        </Box>
                        <Box className={classes.iconPrompt}>
                          <Box>
                            <Typography
                              className={classes.inputlabel}
                              style={{ paddingTop: "10px", paddingLeft: "15px" }}
                            >
                              Do you want to show the Icon ?
                            </Typography>
                          </Box>
                          <Box>
                            <Switch
                              size="small"
                              className={classes.switch}
                              checked={checkedEdit}
                              onChange={handleEditScreenType}
                            />
                          </Box>
                        </Box>
                        <Box margin="20px 20px 0" textAlign="center">
                          <Button
                            variant="contained"
                            style={{ color: "#fff" }}
                            className={classes.saveButton}
                            onClick={() => handleScreenUpdate(index)}
                          >
                            Update
                          </Button>
                        </Box>
                      </CardContent>
                    </Collapse>
                  </Card>
                </SortableScreensItem>
              );
            }}
          />
        </Box>
      </Box>
      <Dialog
        open={openDialog}
        title={
          <span className={classes.dialog}>
            Are you sure you want to delete <span className={classes.dialogFocus}>{screenName.name}</span> screen?
          </span>
        }
        button1="Cancel"
        button2="Yes"
        button1Event={() => setOpenDialog(false)}
        button2Event={() => screenDeleted(screenName)}
        icon={deleteBucket}
        margin="8px"
        width="24px"
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    allScreens: state.screenVisualizationReducer.screens,
    projectDetails: state.ProjectReducer.projectDetails,
    loading: state.screenVisualizationReducer.loading,
    createScreen: state.screenVisualizationReducer.createScreenData,
    screenReset: state.screenVisualizationReducer.screenReset,
    reorderScreenData: state.screenVisualizationReducer.reorderScreenData,
    accessLevel: state.loginReducer.authTokens?.accessLevel,
    RefreshScreen: state.screenVisualizationReducer.refreshRemoveScreen,
    updatedScreenDetails: state.screenVisualizationReducer.updatedScreenDetails,
    deleteScreenData: state.screenVisualizationReducer.deleteScreenRes,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    CreateScreenAction: screenDetails => {
      dispatch(CreateScreenAction(screenDetails));
    },
    getAllScreenAction: projectID => {
      dispatch(getAllScreenAction(projectID));
    },
    getAllScreensDataAction: screens => {
      dispatch(getAllScreensDataAction(screens));
    },
    getScreenResetAction: () => {
      dispatch(getScreenResetAction());
    },
    screenReorderAction: (order, projectId, platform) => {
      dispatch(screenReorderAction(order, projectId, platform));
    },
    deleteScreenAction: screen => {
      dispatch(deleteScreenAction(screen));
    },
    publishScreenAction: details => {
      dispatch(publishScreenAction(details));
    },
    updateScreenDetails: details => {
      dispatch(updateScreenDetails(details));
    },
    clearUpdateScreenDetails: () => {
      dispatch(clearUpdateScreenDetails());
    },
    clearCreateScreenData: () => {
      dispatch(clearCreateScreenData());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screens);
