
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import ProjectIcon from "assets/img/headNavLogo/projectsIcons.svg";
import WidgetIcon from "assets/img/sideNavIcons/WidgetManager.svg";
import AdminIcon from "assets/img/sideNavIcons/Admin.svg";
import ScreenIcon from "assets/img/sideNavIcons/Screens.svg";
import BlackoutIcon from "assets/img/sideNavIcons/blackout.svg";



const Help = () => {
  const [playingVideo, setPlayingVideo] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handlePlay = (url) => {
    setPlayingVideo(url === playingVideo ? null : url);
  };

  const sections = [
    {
      title: 'Projects',
      icon: ProjectIcon,
      tiles: [
        { 
          text: 'Create New Project', 
          url: 'https://youtu.be/UUma5QEreu8', 
          description: 'How to Create New Project from Vivre' 
        },
        { 
          text: 'Add Background Color', 
          url: 'https://youtu.be/jUASDZc1X90', 
          description: 'How to Add background colour from Vivre' 
        },
        { 
          text: 'Add Background Gradient', 
          url: 'https://youtu.be/QzL6HXrvchI', 
          description: 'How to add background gradient from Vivre' 
        },
        { 
          text: 'Add Background Image', 
          url: 'https://youtu.be/IHbo2GRnrv8', 
          description: 'How to add background image from Vivre' 
        },
      ],
    },
    {
      title: 'Screens',
      icon: ScreenIcon,
      tiles: [
        { 
          text: 'Create Screen', 
          url: 'https://youtu.be/WS-hN3SfmVE', 
          description: 'Create a new screen and configure settings.' 
        },
        { 
          text: 'Publish/Unpublish Screen', 
          url: 'https://www.youtube.com/watch?v=VFj2iPrF8WU', 
          description: 'Learn how to publish or unpublish a screen.' 
        },
        { 
          text: 'Reorder screen', 
          url: 'https://youtu.be/W67uBe1Wcyc?si=aZDkryY83ATjmFWf', 
          description: 'How to reorder screens in your project.' 
        },
        { 
          text: 'Export screen', 
          url: 'https://youtu.be/qKlyQyXUApc?si=3oOcziL2qo0G-hUU', 
          description: 'How to export screens in your project.' 
        }
      ],
    },
    {
      title: 'Widgets',
      icon: WidgetIcon,
      tiles: [
        { 
          text: 'Create Widget', 
          url: 'https://www.youtube.com/watch?v=VFj2iPrF8WU', 
          description: 'Learn how to create widgets for your project.' 
        },
        { 
          text: 'Add Widget', 
          url: 'https://youtu.be/tQyH2JT830E?si=vQnG8U8qwFZSGWjH', 
          description: 'Steps to add a widget to a screen.' 
        },
        { 
          text: 'Reorder widget', 
          url: 'https://youtu.be/ED3ha2_7bjk?si=MqeNTDBnQ0lzqF-P', 
          description: 'How to reorder widgets in your screen.' 
        },
      ],
    },
    {
      title: 'Admin',
      icon: AdminIcon,
      tiles: [
        { 
          text: 'Add new user', 
          url: 'https://youtu.be/ulo13hee6Yk', 
          description: 'Guide to creating a new user.' 
        },
        { 
          text: 'Audit logs', 
          url: 'https://youtu.be/MBgSRjw2D7I', 
          description: 'How to make use of Audit logs in Vivre.' 
        },
      ],
    },

    {
      title: 'Blackout',
      icon: BlackoutIcon,
      tiles: [
        { 
          text: 'scheduling or deleting a blackout', 
          url: 'https://youtu.be/l-VAI8ZXNLU', 
          description: 'How to Schedule and Delete a blackout' 
        },
      ],
    },
  ];

  const getTileBackgroundColor = (sectionTitle) => {
    switch (sectionTitle) {
      case 'Projects':
        return '#c9c1d5';
      case 'Screens':
        return '#80c280';
      case 'Widgets':
        return '#64c7f078';
      case 'Admin':
        return '#fdfd9c';
      default:
        return '#f0f0f0';
    }
  };

  const filteredSections = sections
    .map(section => {
      const filteredTiles = section.tiles.filter(tile => 
        tile.text.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tile.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return { ...section, tiles: filteredTiles };
    })
    .filter(section => section.tiles.length > 0); 

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.searchContainer}>
          <label htmlFor="searchInput" style={styles.searchLabel}>Search:</label>
          <input
            id="searchInput"
            type="text"
            placeholder="Search videos..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={styles.searchInput}
            aria-label="Search videos"
          />
        </div>
      </div>

      <div style={styles.content}>
        {filteredSections.length > 0 ? (
          filteredSections.map((section) => (
            <div key={section.title} style={{ marginBottom: '40px' }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img 
                  src={section.icon} 
                  alt={`${section.title} Icon`} 
                  style={{ marginRight: '10px', width: "35px" }} 
                />
                <h3 style={{ margin: "10px" }}><b>{section.title}</b></h3>
              </div>
              <div style={{ display: 'flex', gap: '20px', overflow: "scroll" }}>
                {section.tiles.map((tile) => (
                  <div key={tile.url} style={{ textAlign: 'center', width: '300px' }}>
                    <ReactPlayer
                      url={tile.url}
                      width="300px"
                      height="170px"
                      playing={playingVideo === tile.url}
                      onPlay={() => handlePlay(tile.url)}
                      onPause={() => setPlayingVideo(null)}
                      controls
                      style={{
                        borderRadius: '15px',
                        boxShadow: '0px 5px 10px 0px black',
                      }}
                    />
                    <h4 style={{ fontWeight: 'bold', color: '#333', marginBottom: '5px' }}>
                      {tile.text}
                    </h4>
                    {tile.description && (
                      <p style={{ fontSize: '14px', fontWeight: 'bold', color: '#444', marginTop: '0', marginBottom: '0' }}>
                        {tile.description}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p>No videos found matching your search.</p>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    boxSizing: 'border-box',
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#f9f9f9',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '10px 15px',
    width: '300px', 
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    backgroundColor: '#fff',
  },
  searchLabel: {
    marginRight: '10px',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#333',
  },
  searchInput: {
    border: 'none',
    outline: 'none',
    flex: 1,
    fontSize: '16px',
    padding: '5px',
  },
  content: {
  },
};

export default Help;
