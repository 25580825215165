import React from "react";
import classNames from "classnames";
import VivreAutocomplete from '../Autocomplete/Autocomplete'
import { Box, TextField } from "@material-ui/core";


const AspectRatio = ({
  classes,
  projectAspectRatio,
  bannerSelected,
  getImageDimensions,
  openMandatoryWarn,widgetCommonDetails,imageRatio
}) => {
  console.log(projectAspectRatio,"projectAspectRatio")
  const  handleImageDimensions=(event,dimension,index)=>{
    
    console.log("selected",dimension)
    var ratio = dimension?.trim()?.replace(/\D/g, "/");
    var imageCal = eval(ratio);
    console.log("🚀 ~ handleImageDimensions ~ imageCal:", imageCal)
    
    if (imageCal <= 1.5 && imageCal > 1) {
      getImageDimensions(dimension, 144, 96, 3, index);
  } else if (imageCal > 1.5) {
      getImageDimensions(dimension, 170, 96, 2, index);
  } else if (imageCal > 0.5 && imageCal < 1) {
      getImageDimensions(dimension, 64, 96, 6, index);
  } else if (imageCal === 1) {
      getImageDimensions(dimension, 96, 96, 4, index);
  }
  }
  return (
    <>
    <div
 
                className={classes.widgetDetailContainer}
    >
                <Box sx={{pt:2}}>
                <VivreAutocomplete
             defaultValue = {typeof bannerSelected ==="number"?projectAspectRatio?.[bannerSelected]:""}
            options={
              projectAspectRatio
              }
            optionListStyle = {{width:"330px"}}
            getOptionLabel={(option,i) => option}           
      renderInput={(props) => {
              return <TextField
                {...props}
                 size="small"
                className={classes.textField}
                placeholder="Select Banner Layout*"
                variant="outlined"
                label={"Banner Layout"}
                style={
                  {
                    width: "230px",
                    background: "white",
                    height: "40px",
                    borderRadius: "5px",
                  }
                }
              />
      }}  
            onChange={handleImageDimensions}
        />
                </Box>
      
        
    </div>
       {openMandatoryWarn ? (
              imageRatio ? null : (
                <span className={classes.validationText}>
                  * Mandatory Field
                </span>
              )
            ) : null}
  </>

  );
};

export default AspectRatio;
