import React, { useEffect } from "react";
import { Box, makeStyles, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import VivreAutocomplete from '../Autocomplete/Autocomplete'

import styles from "assets/jss/material-dashboard-react/views/widgetMangerStyle";

const useStyles = makeStyles(styles);

const AdvertisementRailType = (props) => {
  const classes = useStyles();

const[idValue,setIdValue]=React.useState()

useEffect(()=>{
  setIdValue ( props.adWidget.itemData.adUnitInfo.adId) 
},[])

  const getAdPlatform = (e, v) => {
    props.adWidget.itemData.platform = v.code;
  };

  const getAdLayout = (e, v) => {
    props.setAdLayout(v.code);
  };

  const getAdID = (e) => {
setIdValue(e.target.value)
    props.adWidget.itemData.adUnitInfo.adId = e.target.value;
    
  };

  const getWidth = (e)=>{
    props.setAdWidget({...props.adWidget,width:e.target.value})
  }

  const getHeight = (e)=>{
    props.setAdWidget({...props.adWidget,height:e.target.value})
  }

  return (
    <>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // justifyContent: "center",
        alignItems:"center",
        gap:"1rem"
      }}
    >
      <div>
        {/* <Typography>Ad Platform*</Typography> */}
        <div className={classes.widgetDetailContainer}>
          <Box sx={{py:2}} className={classes.validateBox}>
            {/* <Autocomplete
              options={props.adPlatformOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  size="medium"
                  className={classes.textField}
                  {...params}
                  placeholder="Select Ad Platform*"
                  variant="outlined"
                  label={props?.adWidget?.itemData?.platform}
                />
              )}
              onChange={getAdPlatform}
            /> */}
          <VivreAutocomplete
              options={props.adPlatformOptions}
              optionListStyle = {{width:"412px"}}
              getOptionLabel={(option) => option.name}
              defaultValue = {props?.adWidget?.itemData?.platform}
              renderInput={(params) => (
                <TextField
                  size="small"
                  className={classes.textField}
                  {...params}
                  placeholder="Select Ad Platform*"
                  variant="outlined"
                  label={"Ad Platform"}
                  style={{width:"300px"}}
                />
              )}
              onChange={getAdPlatform}
            />
            {props.openMandatoryWarn ? (
              props.adWidget.itemData.platform ? null : (
                <span className={classes.validationText}>* Mandatory Field</span>
              )
            ) : null}
          </Box>
        </div>
      </div>
      <div>
        {/* <Typography>Ad Layout*</Typography> */}
        <div className={classes.widgetDetailContainer}>
          <Box className={classes.validateBox}>
            {/* <Autocomplete
              options={props.adLayoutOptions}
              getOptionLabel={(option) => option.name}
            
              renderInput={(params) => (
                <TextField
                  size="medium"
                  className={classes.textField}
                  {...params}
                  placeholder="Select Ad Layout*"
                  variant="outlined"
                label={props.adLayout}
                />
              )}
              onChange={getAdLayout}
            /> */}
             <VivreAutocomplete
              optionListStyle = {{width:"412px"}}
              options={props.adLayoutOptions}
              getOptionLabel={(option) => option.name}
              defaultValue={props.adLayout}
              renderInput={(params) => (
                <TextField
                  size="small"
                  className={classes.textField}
                  {...params}
                  placeholder="Select Ad Layout*"
                  variant="outlined"
                  label={"Ad Layout"}
                  style={{width:"250px"}}
                />
              )}
              onChange={getAdLayout}
            />
            {props.openMandatoryWarn ? (
              props.adWidget.itemData.platform ? null : (
                <span className={classes.validationText}>* Mandatory Field</span>
              )
            ) : null}
          </Box>
        </div>
      </div>
      {/* <Typography style={{ paddingBottom: "10px" }}>Ad Unit ID*</Typography> */}
    <Box sx={{py:2}}>
    <TextField

value={idValue}
  variant="outlined"
  className={classes.textField}
  size="small"
  placeholder="Enter Ad Unit ID*"
  onChange={getAdID}
  label={"Ad Unit ID"}
/>
{props.openMandatoryWarn ? (
  props.adWidget.itemData.adUnitInfo.adId ? null : (
    <span className={classes.validationText}>* Mandatory Field</span>
  )
) : null}
    </Box>
     
    </div>
       {props.adLayout === 'CUS' ?
        (
          <div style={{display:"flex",gap:"1rem"}}>
            <div>
        <Typography style={{ paddingBottom: "10px",paddingTop:'10px' }}>Width*</Typography>
        <TextField
          variant="outlined"
          className={classes.textField}
          type="number"
          size="small"
          placeholder="Enter Width*"
          value={props?.adWidget?.width}
          InputProps={{
            inputProps: { min: 0 }
          }}
          onChange={getWidth}
        />
         {props.openMandatoryWarn ? (
          props.adWidget.width ? null : (
            <span className={classes.validationText}>* Mandatory Field</span>
          )
        ) : null}
        </div>
        <div>
        <Typography style={{ paddingBottom: "10px",paddingTop:'10px' }}>Height*</Typography>
        <TextField
          variant="outlined"
          className={classes.textField}
          value={props?.adWidget?.height}
          size="small"
          type="number"
          InputProps={{
            inputProps: { min: 0 }
          }}
          placeholder="Enter Height*"
          onChange={getHeight}
        /> {props.openMandatoryWarn ? (
          props.adWidget.height ? null : (
            <span className={classes.validationText}>* Mandatory Field</span>
          )
        ) : null}
        </div>
        </div>
        )
        :"" }
        </>
  );
};

export default AdvertisementRailType;
